import {
  UsePaginationProps,
  UsePaginationResponse,
} from './use-pagination.types';

export function usePagination({
  offset,
  limit,
  totalCount,
}: UsePaginationProps): UsePaginationResponse {
  const firstItemPerPage = offset + 1;
  const lastItem = offset + limit;
  const lastItemPerPage = lastItem >= totalCount ? totalCount : lastItem;

  const currentPage = Math.ceil(firstItemPerPage / limit);
  const lastPage = Math.ceil(totalCount / limit);

  return {
    currentPage,
    firstItemPerPage,
    lastItemPerPage,
    lastPage,
    totalCount,
  };
}
