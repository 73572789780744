import { useIntl } from '@common/i18n';
import { Flex, Text } from '@common/ui-components';
import { formatDateBasedOnTimeZone, isValidIso8601DateFormat } from '@utils';

import { LabeledStrongContentProps } from './labeled-strong-content.types';

export default function LabeledStrongContent({
  label,
  value,
}: LabeledStrongContentProps) {
  const { formatMessage } = useIntl();

  function handleValueType() {
    if (!value) return '--';

    if (isValidIso8601DateFormat(value)) {
      return formatDateBasedOnTimeZone(value, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
    }

    return value;
  }

  return (
    <Flex flex={1} direction="column" gap={1}>
      <Text fontSize={14} color="gray.600">
        {formatMessage({ id: label })}
      </Text>
      <Text as="strong" fontSize="md">
        {handleValueType()}
      </Text>
    </Flex>
  );
}
