import { useIntl } from '@common/i18n';
import { Tag } from '@common/ui-components';
import { deliveriesIntlIds } from '@modules/deliveries/intl';
import { useMemo } from 'react';

import { ModalityTagProps } from './modality-tag.types';

export default function ModalityTag({ type = 'bicycle' }: ModalityTagProps) {
  const { formatMessage } = useIntl();

  const props = useMemo(() => {
    return {
      bicycle: {
        bg: 'blue.100',
        children: formatMessage({
          id: deliveriesIntlIds.details.trip.suggestedModality.tag.bicycle
            .label,
        }),
      },
      motorcycle: {
        fontSize: 'xs',
        bg: 'blue.200',
        children: formatMessage(
          {
            id: deliveriesIntlIds.details.trip.suggestedModality.tag.motorcycle
              .label,
          },
          { suggested_modality: type }
        ),
      },
    }[type];
  }, [formatMessage, type]);

  return <Tag p={1} {...props} />;
}
