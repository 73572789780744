import { styled, TabList } from '@common/ui-components';

export const StyledTabList = styled(TabList)`
  overflow-x: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }
`;
