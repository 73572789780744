import {
  Table as TableComponent,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@common/ui-components';
import { PropsWithChildren, ReactElement } from 'react';

import { TableHeader } from '../table-header';
import { tableContentCss } from './table.styles';
import { TableProps } from './table.types';

export default function Table({
  headers,
  children,
}: PropsWithChildren<TableProps>): ReactElement {
  return (
    <TableContainer flex={1} overflowX="auto">
      <TableComponent css={tableContentCss} variant="striped">
        <TableHead>
          <TableRow>
            <TableHeader headers={headers} />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </TableComponent>
    </TableContainer>
  );
}
