import { useIntl } from '@common/i18n';
import { Flex, Text } from '@common/ui-components';
import { createSpanTagWrapper } from '@utils';
import React, { SyntheticEvent, useCallback } from 'react';

import { PaginationButton } from './components/pagination-button';
import { usePagination } from './hooks';
import { paginationIntlIds } from './intl';
import { PaginationProps } from './pagination.types';
import { createTagWrapper } from './utils/create-tag-wrapper';

export default function Pagination({
  isLoading = false,
  limit = 0,
  offset,
  totalCount = 0,
  onPageChange,
}: PaginationProps) {
  const { formatMessage } = useIntl();

  const { currentPage, lastPage, firstItemPerPage, lastItemPerPage } =
    usePagination({
      offset,
      limit,
      totalCount,
    });

  const createTag = useCallback(createTagWrapper, [currentPage, lastPage]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    const { elements } = event.currentTarget as typeof event.currentTarget & {
      elements: {
        page: {
          value: string;
        };
      };
    };

    const page = +elements.page.value;
    const value = (page - 1 < 0 ? currentPage : page - 1) * limit;

    onPageChange(value);
  };

  return (
    <Flex
      as="form"
      mt="4"
      justify="flex-end"
      align="center"
      onSubmit={handleSubmit}
      display="flex"
      title="pagination form"
    >
      <Text as="span" fontSize="sm" color="gray.600" textAlign="right">
        {isLoading
          ? formatMessage({ id: paginationIntlIds.pagination.loading })
          : formatMessage(
              { id: paginationIntlIds.pagination.items },
              {
                first_item_per_page: firstItemPerPage,
                last_item_per_page: lastItemPerPage,
                total_count: totalCount,
              }
            )}
      </Text>

      <Flex flexShrink={0} alignItems="center">
        <Flex
          alignItems="center"
          mx={7}
          fontSize="sm"
          color="gray.600"
          flexGrow={2}
          flexShrink={0}
          data-testid="pages_informations"
          align="center"
        >
          {formatMessage(
            { id: paginationIntlIds.pagination.pages },
            {
              current_page: isLoading ? 1 : currentPage,
              last_page: isLoading ? null : lastPage,
              span: createSpanTagWrapper,
              tag: createTag({
                ref: inputRef,
                currentPage,
                max: lastPage,
              }),
            }
          )}
        </Flex>

        <PaginationButton
          onPageChange={() => {
            if (inputRef.current) {
              inputRef.current.value = String(currentPage - 1);
              onPageChange(offset - limit);
            }
          }}
          isDisabled={isLoading || currentPage === 1}
        >
          arrow_back_ios
        </PaginationButton>

        <PaginationButton
          onPageChange={() => {
            if (inputRef.current) {
              inputRef.current.value = String(currentPage + 1);
              onPageChange(offset + limit);
            }
          }}
          isDisabled={isLoading || currentPage === lastPage}
        >
          arrow_forward_ios
        </PaginationButton>
      </Flex>
    </Flex>
  );
}
