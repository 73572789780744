import { ReactNode } from 'react';

import { InputPage } from '../../components/input-page';
import { InputPageProps } from '../../components/input-page/input-page.types';

export default function createTagWrapper(props: InputPageProps) {
  return function createTagWrapperNested(_chunks: ReactNode[]) {
    return <InputPage {...props} />;
  };
}
