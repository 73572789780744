export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Address = {
  __typename?: 'Address';
  formatted: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export enum CancelExternalDispatchAgent {
  Client = 'Client',
  Internal = 'Internal',
  Rider = 'Rider'
}

export type CancelExternalDispatchInput = {
  agent: CancelExternalDispatchAgent;
  reason: CancelExternalDispatchReason;
};

export enum CancelExternalDispatchReason {
  ClientCancelation = 'ClientCancelation',
  ClientFraudNoOrder = 'ClientFraudNoOrder',
  ClientFraudNoReceive = 'ClientFraudNoReceive',
  ClientNoReceive = 'ClientNoReceive',
  ClientWrongInfo = 'ClientWrongInfo',
  InternalAvailableRider = 'InternalAvailableRider',
  InternalWaitTimeTooLong = 'InternalWaitTimeTooLong',
  InternalWrongRequest = 'InternalWrongRequest',
  RiderAddressNotFound = 'RiderAddressNotFound',
  RiderClientNotFound = 'RiderClientNotFound',
  RiderFraud = 'RiderFraud',
  RiderRiskArea = 'RiderRiskArea',
  RiderUnexpected = 'RiderUnexpected'
}

export type ChangeDeliveryStatusPayloadInput = {
  confirmDeliveryReceipt: ConfirmDeliveryReceiptPayloadInput;
};

export type ConfirmDeliveryReceiptPayloadInput = {
  returnConfirmedBy: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  address: Address;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type DeliveriesCounted = {
  __typename?: 'DeliveriesCounted';
  totalCount: Scalars['Int'];
};

export type DeliveriesPaginated = {
  __typename?: 'DeliveriesPaginated';
  deliveries: Array<Delivery>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Delivery = {
  __typename?: 'Delivery';
  customer: Customer;
  eta?: Maybe<Scalars['DateTime']>;
  hub: DeliveryHub;
  id: Scalars['String'];
  order: Order;
  returnDetails?: Maybe<ReturnDetails>;
  status: DeliveryStatus;
  statusHistory?: Maybe<Array<StatusHistory>>;
  suggestedModality?: Maybe<Modality>;
  trips: Array<DeliveryTrip>;
};

export type DeliveryHub = {
  __typename?: 'DeliveryHub';
  alias: Scalars['String'];
  id: Scalars['String'];
};

export type DeliveryRider = {
  __typename?: 'DeliveryRider';
  fullName: Scalars['String'];
  id: Scalars['String'];
};

export enum DeliveryStatus {
  Arrived = 'arrived',
  Canceled = 'canceled',
  Delivered = 'delivered',
  InManualAnalysis = 'in_manual_analysis',
  OnTheWay = 'on_the_way',
  Pending = 'pending',
  Picking = 'picking',
  PreProcessed = 'pre_processed',
  Processed = 'processed',
  Ready = 'ready',
  Unknown = 'unknown'
}

export type DeliveryTrip = {
  __typename?: 'DeliveryTrip';
  externalDispatch?: Maybe<ExternalDispatch>;
  id: Scalars['String'];
  isStack: Scalars['Boolean'];
  mode: TripMode;
  points?: Maybe<Array<DeliveryTripPoint>>;
  rider?: Maybe<DeliveryRider>;
  status: TripStatus;
};

export type DeliveryTripPoint = {
  __typename?: 'DeliveryTripPoint';
  arrivedAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  referenceId: Scalars['String'];
};

export type ExternalDispatch = {
  __typename?: 'ExternalDispatch';
  cancelationReason?: Maybe<Scalars['String']>;
  externalReferenceId?: Maybe<Scalars['String']>;
  requestReason?: Maybe<Scalars['String']>;
  riderName?: Maybe<Scalars['String']>;
  riderPhone?: Maybe<Scalars['String']>;
  status: ExternalDispatchStatus;
  trackingUrl?: Maybe<Scalars['String']>;
  vehicleColor?: Maybe<Scalars['String']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
};

export enum ExternalDispatchStatus {
  Arrived = 'arrived',
  Canceled = 'canceled',
  Delivered = 'delivered',
  Failed = 'failed',
  OnTheWay = 'on_the_way',
  Pending = 'pending',
  Returned = 'returned',
  Returning = 'returning',
  RiderAssigned = 'rider_assigned',
  Unknown = 'unknown'
}

export type Hub = {
  __typename?: 'Hub';
  address: Scalars['String'];
  alias: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deliveriesInfo?: Maybe<HubDeliveriesInfo>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  journeysInfo?: Maybe<HubJourneysInfo>;
};

export type HubDeliveriesInfo = {
  __typename?: 'HubDeliveriesInfo';
  atHub: HubDeliveriesInfoDetail;
  inRoute: HubDeliveriesInfoDetail;
  total: Scalars['Int'];
};

export type HubDeliveriesInfoDetail = {
  __typename?: 'HubDeliveriesInfoDetail';
  alreadyLate: Scalars['Int'];
  inScandal: Scalars['Int'];
  onTime: Scalars['Int'];
  total: Scalars['Int'];
};

export type HubJourneysInfo = {
  __typename?: 'HubJourneysInfo';
  atHub: JourneysAtHubInfo;
  inRoute: JourneysInRouteInfo;
  total: Scalars['Int'];
};

export type HubsPaginated = {
  __typename?: 'HubsPaginated';
  hubs: Array<Hub>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Journey = {
  __typename?: 'Journey';
  actions: Array<JourneyAction>;
  createdAt: Scalars['DateTime'];
  finishedAt?: Maybe<Scalars['DateTime']>;
  hasTripStarted?: Maybe<Scalars['Boolean']>;
  hub: JourneyHub;
  id: Scalars['String'];
  modality?: Maybe<Modality>;
  rider: JourneyRider;
  status: JourneyStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JourneyAction = {
  __typename?: 'JourneyAction';
  createdAt: Scalars['DateTime'];
  name: JourneyActionName;
  processedAt?: Maybe<Scalars['DateTime']>;
  securityCode?: Maybe<Scalars['String']>;
  status: JourneyActionStatus;
};

export enum JourneyActionName {
  Cancel = 'cancel',
  Expire = 'expire',
  Finish = 'finish',
  Pause = 'pause',
  Resume = 'resume',
  Start = 'start',
  Unknown = 'unknown'
}

export enum JourneyActionStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Failed = 'failed',
  Processing = 'processing',
  Unknown = 'unknown'
}

export type JourneyHub = {
  __typename?: 'JourneyHub';
  alias: Scalars['String'];
  id: Scalars['String'];
};

export type JourneyRider = {
  __typename?: 'JourneyRider';
  fullName: Scalars['String'];
  id: Scalars['String'];
};

export enum JourneyStatus {
  Available = 'available',
  Canceled = 'canceled',
  InRoute = 'inRoute',
  Offline = 'offline',
  Paused = 'paused',
  Pending = 'pending',
  Unknown = 'unknown'
}

export type JourneysAtHubInfo = {
  __typename?: 'JourneysAtHubInfo';
  assignedToTrip: Scalars['Int'];
  available: Scalars['Int'];
  paused: Scalars['Int'];
  total: Scalars['Int'];
};

export type JourneysInRouteInfo = {
  __typename?: 'JourneysInRouteInfo';
  headingHub: Scalars['Int'];
  inTrip: Scalars['Int'];
  total: Scalars['Int'];
};

export type JourneysPaginated = {
  __typename?: 'JourneysPaginated';
  journeys: Array<Journey>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum Modality {
  Bicycle = 'bicycle',
  Motorcycle = 'motorcycle'
}

export type Mutation = {
  __typename?: 'Mutation';
  assignTripToRider: Trip;
  cancelExternalDispatch: Trip;
  changeStatusDelivery?: Maybe<Delivery>;
  finishJourney?: Maybe<Journey>;
  requestExternalDispatch: Trip;
};


export type MutationAssignTripToRiderArgs = {
  deliveryId: Scalars['String'];
  riderId?: InputMaybe<Scalars['String']>;
  tripId: Scalars['String'];
};


export type MutationCancelExternalDispatchArgs = {
  input: CancelExternalDispatchInput;
  tripId: Scalars['String'];
};


export type MutationChangeStatusDeliveryArgs = {
  deliveryId: Scalars['String'];
  payload?: InputMaybe<ChangeDeliveryStatusPayloadInput>;
  status: DeliveryStatus;
};


export type MutationFinishJourneyArgs = {
  journeyId: Scalars['String'];
};


export type MutationRequestExternalDispatchArgs = {
  input: RequestExternalDispatchInput;
  tripId: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  confirmedAt: Scalars['DateTime'];
  id: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  countedDeliveriesByStatuses: DeliveriesCounted;
  deliveriesPaginated: DeliveriesPaginated;
  delivery?: Maybe<Delivery>;
  hub?: Maybe<Hub>;
  hubsPaginated: HubsPaginated;
  journeysForTrip: Array<Journey>;
  journeysPaginated: JourneysPaginated;
  me?: Maybe<User>;
  ridersPaginated: RidersPaginated;
};


export type QueryCountedDeliveriesByStatusesArgs = {
  statuses: Array<DeliveryStatus>;
};

export type QueryDeliveriesPaginatedArgs = {
  hubIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['String']>;
  riderId?: InputMaybe<Scalars['String']>;
  status: Array<DeliveryStatus>;
};


export type QueryDeliveryArgs = {
  id: Scalars['String'];
  onCompleteSubscription?: (
    previousData: Delivery,
    updatedDelivery: Delivery
  ) => void;
};


export type QueryHubArgs = {
  id: Scalars['String'];
};


export type QueryHubsPaginatedArgs = {
  hubIds?: InputMaybe<Array<Scalars['String']>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryJourneysForTripArgs = {
  deliveryId: Scalars['String'];
  tripId: Scalars['String'];
};


export type QueryJourneysPaginatedArgs = {
  hubIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<JourneyStatus>>;
};


export type QueryRidersPaginatedArgs = {
  document?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RequestExternalDispatchInput = {
  reason: RequestExternalDispatchReason;
  size: RequestExternalDispatchSize;
};

export enum RequestExternalDispatchReason {
  Distance = 'Distance',
  LargeOrder = 'LargeOrder',
  NoRiders = 'NoRiders',
  Rain = 'Rain',
  Strike = 'Strike'
}

export enum RequestExternalDispatchSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
  XLarge = 'XLarge'
}

export type ReturnDetails = {
  __typename?: 'ReturnDetails';
  canceledAt?: Maybe<Scalars['DateTime']>;
  returnConfirmedBy?: Maybe<Scalars['String']>;
  returnedAt?: Maybe<Scalars['DateTime']>;
  tripPointCancelationReason?: Maybe<Scalars['String']>;
  tripPointCanceledAt?: Maybe<Scalars['DateTime']>;
};

export type Rider = {
  __typename?: 'Rider';
  birthdate?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  document?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  modality?: Maybe<Modality>;
  verified: Scalars['Boolean'];
};

export type RidersPaginated = {
  __typename?: 'RidersPaginated';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  riders: Array<Rider>;
  totalCount: Scalars['Int'];
};

export type StatusHistory = {
  __typename?: 'StatusHistory';
  createdAt: Scalars['DateTime'];
  statusFrom: Scalars['String'];
  statusTo: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  deliveryUpdated: Delivery;
  hubUpdated: Hub;
};


export type SubscriptionDeliveryUpdatedArgs = {
  id: Scalars['String'];
};


export type SubscriptionHubUpdatedArgs = {
  id: Scalars['String'];
};

export type Trip = {
  __typename?: 'Trip';
  externalDispatch?: Maybe<ExternalDispatch>;
  id: Scalars['String'];
  mode: TripMode;
  rider?: Maybe<Rider>;
  status: TripStatus;
};

export enum TripMode {
  Automatic = 'automatic',
  External = 'external',
  Manual = 'manual'
}

export enum TripStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Pending = 'pending',
  Started = 'started',
  Unknown = 'unknown',
  Voided = 'voided'
}

export type User = {
  __typename?: 'User';
  accessType: UserAccessType;
  email: Scalars['String'];
  hubIds: Array<Scalars['String']>;
  hubs: Array<Hub>;
  locale: Scalars['String'];
  name: Scalars['String'];
  pictureUrl: Scalars['String'];
};

export enum UserAccessType {
  Admin = 'Admin',
  Ct = 'CT',
  H = 'H',
  Ro = 'RO',
  Unknown = 'unknown'
}
