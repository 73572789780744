import {
  Flex,
  ValueContainer as SelectValueContainer,
  ValueContainerProps,
} from '@common/ui-components';
import { ReactNode } from 'react';

import { ALL_VALUES_OPTION } from '../multi-select.constants';
import { Value } from '../multi-select.types';

export default function ValueContainer({
  children,
  ...props
}: ValueContainerProps) {
  const currentValues = props.getValue() as Value[];
  const child = children as ReactNode[][];
  const isAllValuesSelected = currentValues.some(
    (data) => data.value === ALL_VALUES_OPTION.value
  );

  return (
    <SelectValueContainer {...props}>
      <Flex>{isAllValuesSelected ? [[child[0][0]], child[1]] : children}</Flex>
    </SelectValueContainer>
  );
}
