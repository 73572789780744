import { Flex, Spinner } from '@common/ui-components';

export default function SpinnerLoading({
  position = 'unset',
}: {
  position?: string;
}) {
  return (
    <Flex
      style={{ position } as React.CSSProperties}
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner thickness="4px" speed="0.65s" color="blue.500" size="xl" />
    </Flex>
  );
}
