import { Flex, Option as SelectOption } from '@common/ui-components';
import { OptionProps } from '@common/ui-components';
import { noop } from '@utils';

export default function Option(props: OptionProps) {
  return (
    <SelectOption {...props}>
      <Flex gap={2}>
        <input type="checkbox" checked={props.isSelected} onChange={noop} />
        <label>{props.label}</label>
      </Flex>
    </SelectOption>
  );
}
