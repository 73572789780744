import { Button } from '@common/ui-components';
import { PropsWithChildren } from 'react';

import { PaginationButtonProps } from './pagination-button.types';

export default function PaginationButton({
  isDisabled,
  onPageChange,
  children,
}: PropsWithChildren<PaginationButtonProps>) {
  return (
    <Button
      className="material-symbols-outlined"
      size="sm"
      fontSize="md"
      width="4"
      color="gray.600"
      bg="transparent"
      _hover={{
        bg: 'transparent',
      }}
      _active={{
        bg: 'transparent',
      }}
      onClick={onPageChange}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
}
