import { useIntl } from '@common/i18n';
import { Box, Divider, Flex, Grid, Text } from '@common/ui-components';
import { PropsWithChildren } from 'react';

import { StrongLabeledContentWithIconProps } from './strong-labeled-content-with-icon.types';
export default function StrongLabeledContentWithIcon({
  icon,
  label,
  showDivider = false,
  children,
}: PropsWithChildren<StrongLabeledContentWithIconProps>) {
  const { formatMessage } = useIntl();
  return (
    <Flex direction="column" gap={4}>
      <Flex align="center" gap={2}>
        <Box color="blue.600" fontSize={14}>
          {icon}
        </Box>

        <Text as="strong" fontSize={14} whiteSpace="nowrap">
          {formatMessage({ id: label })}
        </Text>

        {showDivider ? <Divider /> : null}
      </Flex>

      <Grid gridTemplateColumns="repeat(2, 1fr)" gap={4}>
        {children}
      </Grid>
    </Flex>
  );
}
