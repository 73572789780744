import { useIntl } from '@common/i18n';
import { Text } from '@common/ui-components';
import {
  HIGH_STATUS_COLOR,
  MEDIUM_STATUS_COLOR,
  NORMAL_STATUS_COLOR,
  setTextColorByStatus,
} from '@utils';
import { useMemo } from 'react';

import {
  HIGH_WARNING_VALUE,
  MEDIUM_WARNING_VALUE,
} from './countdown.constants';
import { CountdownProps } from './countdown.types';
import { countdownIntlIds } from './intl';
import { useCountdown } from './use-countdown.hook';

export default function Countdown({ startFrom }: CountdownProps) {
  const { formatMessage } = useIntl();

  const { timer, isProgressive } = useCountdown({ startFrom });

  const props = useMemo(() => {
    if (timer <= HIGH_WARNING_VALUE || isProgressive) {
      return setTextColorByStatus(HIGH_STATUS_COLOR);
    }

    if (timer <= MEDIUM_WARNING_VALUE) {
      return setTextColorByStatus(MEDIUM_STATUS_COLOR);
    }

    return setTextColorByStatus(NORMAL_STATUS_COLOR);
  }, [timer, isProgressive]);

  const countdownLabel = isProgressive
    ? countdownIntlIds.progressive.label
    : countdownIntlIds.regressive.label;

  return (
    <Text {...props}>
      {formatMessage(
        {
          id: countdownLabel,
        },
        { timer }
      )}
    </Text>
  );
}
