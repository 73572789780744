import { useIntl } from '@common/i18n';
import { Tag } from '@common/ui-components';
import { ReactElement, useMemo } from 'react';

import { statusTagIntlIds } from './intl';
import { StatusTagProps } from './status-tag.types';

export default function StatusTag({
  isActive = true,
}: StatusTagProps): ReactElement {
  const { formatMessage } = useIntl();

  const props = useMemo(() => {
    if (isActive) {
      return {
        bg: 'green.200',
        color: 'gray.800',
        children: formatMessage({
          id: statusTagIntlIds.active,
        }),
      };
    }

    return {
      bg: 'gray.300',
      color: 'gray.800',
      children: formatMessage({
        id: statusTagIntlIds.inactive,
      }),
    };
  }, [isActive, formatMessage]);

  return <Tag p={1} {...props} />;
}
