import { TableCellHead } from '@common/ui-components';
import { ReactElement } from 'react';

import { TableHeaderProps } from './table-header.types';

export default function TableHeader({
  headers,
}: TableHeaderProps): ReactElement {
  return (
    <>
      {headers.map(({ label, ...props }, index) => (
        <TableCellHead
          key={index}
          overflow="hidden"
          textOverflow="ellipsis"
          color="gray.600"
          fontSize="xs"
          p={2}
          {...props}
        >
          {label}
        </TableCellHead>
      ))}
    </>
  );
}
