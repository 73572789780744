import { Input } from '@common/ui-components';
import Flex from '@common/ui-components/flex/flex';
import { FormEvent, forwardRef, ReactElement, Ref, useState } from 'react';

import { StyledLabel } from './input-page.styles';
import { InputPageProps } from './input-page.types';

export default forwardRef(function InputPage(
  { currentPage, max }: InputPageProps,
  ref: Ref<HTMLInputElement>
): ReactElement {
  const [state, setState] = useState(currentPage);

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setState(+event.currentTarget.value);
  };

  return (
    <Flex position="relative" mx={2}>
      <Flex color="transparent" px={3} minW={8} height={10} aria-hidden="true">
        <StyledLabel htmlFor="pagination-input-page">Page</StyledLabel>

        <Input
          aria-label="Page"
          px={1}
          position="absolute"
          width="100%"
          left={0}
          right={0}
          ref={ref}
          name="page"
          id="pagination-input-page"
          defaultValue={state}
          type="number"
          min={1}
          max={max}
          bg="gray.100"
          color="gray.600"
          borderRadius="md"
          textAlign="center"
          onChange={handleChange}
          isRequired
        />
        {state}
      </Flex>
    </Flex>
  );
});
