import { Text } from '@common/ui-components';
import { PropsWithChildren, ReactElement } from 'react';

export default function TitlePage({
  children,
}: PropsWithChildren): ReactElement {
  return (
    <Text as="h1" fontSize="3xl" fontWeight="600">
      {children}
    </Text>
  );
}
