import { ActionMeta, MultiSelect as Select } from '@common/ui-components';
import { noop } from '@utils';
import { ReactElement, useEffect, useMemo, useState } from 'react';

import { ALL_VALUES_OPTION } from './multi-select.constants';
import { MultiSelectProps, Value } from './multi-select.types';
import { MultiValue } from './multi-value';
import { Option } from './option';
import { ValueContainer } from './value-container';

export default function MultiSelect({
  placeholder,
  allValuesLabel = ALL_VALUES_OPTION.label,
  noOptionsLabel,
  name,
  onChange = noop,
  options,
  value,
}: MultiSelectProps): ReactElement {
  const allValues = useMemo(() => {
    return {
      label: allValuesLabel,
      value: ALL_VALUES_OPTION.value,
    };
  }, [allValuesLabel]);

  const [selectedValues, setSelectedValue] = useState<Value[] | null>(null);

  useEffect(() => {
    if (value === null || value === undefined || value.length === 0) {
      setSelectedValue(null);
      return;
    }

    if (value.length === options.length) {
      setSelectedValue([allValues, ...options]);
      return;
    }

    setSelectedValue(value);
  }, [allValues, options, value]);

  return (
    <Select
      name={name}
      isMulti
      options={options.length ? [allValues, ...options] : options}
      placeholder={placeholder}
      noOptionsMessage={() => noOptionsLabel}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      useBasicStyles
      components={{
        Option,
        MultiValue,
        ValueContainer,
      }}
      value={selectedValues}
      onChange={(selected: Value[], event: ActionMeta<Value>) => {
        if (
          (event.action === 'deselect-option' &&
            event.option!.value === allValues.value) ||
          (event.action === 'remove-value' &&
            event.removedValue.value === allValues.value)
        ) {
          setSelectedValue(null);
          onChange(null);
          return;
        }

        if (
          event.action === 'deselect-option' &&
          selected.length === options.length
        ) {
          const newValue = options.filter(
            (item) => item.value === event.option!.value
          );

          setSelectedValue(newValue);
          onChange(newValue);
          return;
        }

        if (
          selected.length &&
          (selected.length === options.length ||
            selected.find((option) => option.value === allValues.value))
        ) {
          setSelectedValue([allValues, ...options]);
          onChange(options);
          return;
        }

        setSelectedValue(selected);
        onChange(selected);
      }}
    />
  );
}
