import { formatTimer } from '@utils';
import { useCallback, useEffect, useState } from 'react';

import { REFRESH_COUNTDOWN_INTERVAL } from './countdown.constants';
import { UseCountdownProps } from './countdown.types';

export function useCountdown({ startFrom }: UseCountdownProps) {
  const initialDiff = new Date(startFrom).getTime() - new Date().getTime();

  const [diff, setDiff] = useState<number>(Math.abs(initialDiff));
  const [isProgressive, setIsProgressive] = useState<boolean>(initialDiff < 0);

  const refresh = useCallback(() => {
    const countdown = new Date(startFrom).getTime();

    const now = new Date().getTime();
    const diff = countdown - now;

    if (diff < 0) {
      setDiff(Math.abs(diff));
      setIsProgressive(true);
    } else {
      setDiff(diff);
    }
  }, [startFrom]);

  useEffect(
    function refreshCountdown() {
      const interval = setInterval(() => {
        refresh();
      }, REFRESH_COUNTDOWN_INTERVAL);

      return () => clearInterval(interval);
    },
    [refresh]
  );

  return { timer: formatTimer(diff), isProgressive };
}
